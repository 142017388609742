import {
  AbilityContext,
  checkType,
  CtyShowToast,
  injectPalChatbot,
  type SidebarItem,
} from '@circuitry-ai/doc-ui';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const SidebarItems = () => {
  const { t } = useTranslation();

  const ability = useContext(AbilityContext);

  const OrchestrationSidebarItems = [
    {
      key: 'advisors',
      href: '/advisors',
      icon: 'octicon:people-24',
      title: t('AIdvisors'),
    },

  ]

  if (ability.can("analytics", "Dashboard")) {
    OrchestrationSidebarItems.push({
      key: 'analyst',
      href: '/analytics/list',
      icon: 'hugeicons:chart-bar-line',
      title: t('AInalysts'),
    })
  }

  if (ability.can("pdfscanner", "Agent")) {
    OrchestrationSidebarItems.push({
      key: 'pdfscanner',
      href: '/aigent/pdfscanner',
      icon: 'material-symbols:scan-outline',
      title: t('PDF Scanner'),
    });
  }
  if (ability.can("automations", "Agent")) {
    OrchestrationSidebarItems.push({
      key: 'automation',
      href: '/aigent/automation',
      icon: 'carbon:workflow-automation',
      title: t('Automations'),
    });
  }

  const intelligenceSidenavItems = () => {
    return [
      {
        key: 'knowledge',
        href: '/knowledge',
        icon: 'lucide:copy-check',
        title: t('Knowledge'),
      },
      {
        key: 'conversations',
        href: '/conversations',
        icon: 'ph:chat-text',
        title: t('Conversations'),
      }]
  }


  return [
    {
      key: 'dashboard',
      href: '/',
      icon: 'radix-icons:home',
      title: t('Dashboard'),
    },
    {
      key: 'octr',
      title: t('AI Assistants'),
      items: OrchestrationSidebarItems
    },
    (intelligenceSidenavItems().length > 0) && ({
      key: 'agent',
      title: t('Intelligence Platform'),
      items: intelligenceSidenavItems()
    }),
    {
      key: 'sys',
      title: t('System'),
      items: [
        {
          key: 'settings',
          href: '/settings',
          icon: 'qlementine-icons:settings-16',
          title: t('Settings'),
        },
        {
          key: 'help',
          // href: "/help",
          icon: 'tdesign:support',
          title: t('Support'),
        },
      ],
    },
    {
      key: 'pal',
      title: (
        <div className="md:hidden block">
          {' '}
          {/* Apply responsive classes here */}
          {t('Launch PAL')}
        </div>
      ),
      items: [
        {
          key: 'New Window',
          icon: 'fluent:window-new-20-regular',
          title: t('New Window'),
          className: ' md:hidden',
          onClick: () => {
            window.open(
              'https://pal.circuitry.ai/',
              '_blank',
              'noopener,noreferrer'
            );
          },
        },
        {
          key: 'Widget',
          icon: 'heroicons:chat-bubble-left-right',
          title: t('Widget'),
          className: ' md:hidden',
          onClick: () => {
            injectPalChatbot(CtyShowToast, checkType);
          },
        },
      ],
    },
  ] as SidebarItem[];
};

export default SidebarItems;
